@import url(https://fonts.googleapis.com/css?family=Raleway:400);

body {
  font-family: 'Raleway','Helvetica Neue', Arial, sans-serif;

  .app-wrapper {
    margin: 0 auto;
    text-align: center;

    header {
      width: 100%;
    }

    /* tablets and desktop */
    @media only screen
      and (min-width: 768px) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }


    /* desktops */
    @media only screen
        and (min-width: 1200px) {
        max-width: 1200px;
    }

    .number-module {
      background-color: lightcyan;
      border: solid 1px lightgrey;
      border-radius: 8px;
      padding: 30px;
      margin: 0 0 0.5em 0;
      transition: all 0.5s ease;
      // background: yellow;

      font: {
        size: 1.2em;
        weight: bold;
      }

      /* tablets and desktop */
      @media only screen
        and (min-width: 768px) {
        // background: olive;
        width: 41%;
      }
      /* desktop */
      @media only screen
        and (min-width: 1200px) {
        // background: blue;
        width: 25%;
      }

      &.active {
        color: #ffffff;
        background-color: rgb(163, 75, 137);
        border-color: purple;
      }

      &.multiple {
        background-color: lightpink;
      }
    }
  }

}


